import { useAtomValue } from 'jotai';

import { metadataAtom } from './jotai-atoms';

const useMetadata = () => {
  const metadata = useAtomValue(metadataAtom);

  // Constants shortcuts while metadata is null
  const cweMetadata = metadata?.constants.cwe || [];
  const owaspMetadata = metadata?.constants.owasp || [];
  const dataElementTagMetadata = metadata?.constants.dataElementTag || [];
  const dataElementSensitivityMetadata = metadata?.constants.sensitivity || [];
  const vulnerabilitySeverityMetadata = metadata?.constants.severity || [];
  const sourceMetadata = metadata?.constants.source || [];
  const ropaAssociatedIndividualMetadata =
    metadata?.constants?.ropaAssociatedIndividual || [];
  const ropaDataSourceMetadata = metadata?.constants?.ropaDataSource || [];
  const ropaDataUsageMetadata = metadata?.constants?.ropaDataUsage || [];
  const ropaSecurityMeasureMetadata =
    metadata?.constants?.ropaSecurityMeasure || [];
  const ropaOrganizationRoleMetadata =
    metadata?.constants?.ropaOrganizationRole || [];
  const ropaLegalBasisMetadata = metadata?.constants?.ropaLegalBasis || [];
  const sanitizerTypeMetadata = metadata?.constants.sanitizerType || [];
  return {
    metadata,
    cweMetadata,
    owaspMetadata,
    dataElementTagMetadata,
    dataElementSensitivityMetadata,
    vulnerabilitySeverityMetadata,
    sourceMetadata,
    ropaAssociatedIndividualMetadata,
    ropaDataSourceMetadata,
    ropaDataUsageMetadata,
    ropaSecurityMeasureMetadata,
    ropaOrganizationRoleMetadata,
    ropaLegalBasisMetadata,
    sanitizerTypeMetadata,
  } as const;
};

export { useMetadata };
